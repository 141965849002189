.codehilite pre {
    background-color: hsl(0, 0%, 100%);
    -webkit-font-smoothing: auto;
}
.codehilite .hll {
    background-color: hsl(60, 100%, 90%);
}
.codehilite {
    background-color: hsl(0, 0%, 98%);
}
.codehilite .c {
    color: hsl(180, 33%, 37%);
    font-style: italic;
} /* Comment */
.codehilite .err {
    border: 1px solid hsl(0, 100%, 50%);
} /* Error */
.codehilite .k {
    color: hsl(332, 70%, 38%);
} /* Keyword */
.codehilite .o {
    color: hsl(332, 70%, 38%);
} /* Operator */
.codehilite .cm {
    color: hsl(180, 33%, 37%);
    font-style: italic;
} /* Comment.Multiline */
.codehilite .cp {
    color: hsl(38, 100%, 36%);
} /* Comment.Preproc */
.codehilite .c1 {
    color: hsl(0, 0%, 67%);
    font-style: italic;
} /* Comment.Single */
.codehilite .cs {
    color: hsl(180, 33%, 37%);
    font-style: italic;
} /* Comment.Special */
.codehilite .gd {
    color: hsl(0, 100%, 31%);
} /* Generic.Deleted */
.codehilite .ge {
    font-style: italic;
} /* Generic.Emph */
.codehilite .gr {
    color: hsl(0, 100%, 50%);
} /* Generic.Error */
.codehilite .gh {
    color: hsl(240, 100%, 25%);
    font-weight: bold;
} /* Generic.Heading */
.codehilite .gi {
    color: hsl(120, 100%, 31%);
} /* Generic.Inserted */
.codehilite .go {
    color: hsl(0, 0%, 50%);
} /* Generic.Output */
.codehilite .gp {
    color: hsl(240, 100%, 25%);
    font-weight: bold;
} /* Generic.Prompt */
.codehilite .gs {
    font-weight: bold;
} /* Generic.Strong */
.codehilite .gu {
    color: hsl(300, 100%, 25%);
    font-weight: bold;
} /* Generic.Subheading */
.codehilite .gt {
    color: hsl(221, 100%, 40%);
} /* Generic.Traceback */
.codehilite .kc {
    color: hsl(332, 70%, 38%);
    font-weight: bold;
} /* Keyword.Constant */
.codehilite .kd {
    color: hsl(332, 70%, 38%);
} /* Keyword.Declaration */
.codehilite .kn {
    color: hsl(332, 70%, 38%);
    font-weight: bold;
} /* Keyword.Namespace */
.codehilite .kp {
    color: hsl(332, 70%, 38%);
} /* Keyword.Pseudo */
.codehilite .kr {
    color: hsl(332, 70%, 38%);
    font-weight: bold;
} /* Keyword.Reserved */
.codehilite .kt {
    color: hsl(332, 70%, 38%);
} /* Keyword.Type */
.codehilite .m {
    color: hsl(0, 0%, 40%);
} /* Literal.Number */
.codehilite .s {
    color: hsl(86, 57%, 40%);
} /* Literal.String */
.codehilite .na {
    color: hsl(71, 55%, 36%);
} /* Name.Attribute */
.codehilite .nb {
    color: hsl(195, 100%, 35%);
} /* Name.Builtin */
.codehilite .nc {
    color: hsl(264, 27%, 50%);
    font-weight: bold;
} /* Name.Class */
.codehilite .no {
    color: hsl(0, 100%, 26%);
} /* Name.Constant */
.codehilite .nd {
    color: hsl(276, 100%, 56%);
} /* Name.Decorator */
.codehilite .ni {
    color: hsl(0, 0%, 60%);
    font-weight: bold;
} /* Name.Entity */
.codehilite .ne {
    color: hsl(2, 62%, 52%);
    font-weight: bold;
} /* Name.Exception */
.codehilite .nf {
    color: hsl(264, 27%, 50%);
} /* Name.Function */
.codehilite .nl {
    color: hsl(60, 100%, 31%);
} /* Name.Label */
.codehilite .nn {
    color: hsl(264, 27%, 50%);
    font-weight: bold;
} /* Name.Namespace */
.codehilite .nt {
    color: hsl(120, 100%, 25%);
    font-weight: bold;
} /* Name.Tag */
.codehilite .nv {
    color: hsl(241, 68%, 28%);
} /* Name.Variable */
.codehilite .nx {
    color: hsl(0, 0%, 26%);
} /* Not sure? */
.codehilite .ow {
    color: hsl(276, 100%, 56%);
    font-weight: bold;
} /* Operator.Word */
.codehilite .w {
    color: hsl(0, 0%, 73%);
} /* Text.Whitespace */
.codehilite .mf {
    color: hsl(195, 100%, 35%);
} /* Literal.Number.Float */
.codehilite .mh {
    color: hsl(195, 100%, 35%);
} /* Literal.Number.Hex */
.codehilite .mi {
    color: hsl(195, 100%, 35%);
} /* Literal.Number.Integer */
.codehilite .mo {
    color: hsl(195, 100%, 35%);
} /* Literal.Number.Oct */
.codehilite .sb {
    color: hsl(86, 57%, 40%);
} /* Literal.String.Backtick */
.codehilite .sc {
    color: hsl(86, 57%, 40%);
} /* Literal.String.Char */
.codehilite .sd {
    color: hsl(86, 57%, 40%);
    font-style: italic;
} /* Literal.String.Doc */
.codehilite .s2 {
    color: hsl(225, 71%, 33%);
} /* Literal.String.Double */
.codehilite .se {
    color: hsl(26, 69%, 43%);
    font-weight: bold;
} /* Literal.String.Escape */
.codehilite .sh {
    color: hsl(86, 57%, 40%);
} /* Literal.String.Heredoc */
.codehilite .si {
    color: hsl(336, 38%, 56%);
    font-weight: bold;
} /* Literal.String.Interpol */
.codehilite .sx {
    color: hsl(120, 100%, 25%);
} /* Literal.String.Other */
.codehilite .sr {
    color: hsl(189, 54%, 49%);
} /* Literal.String.Regex */
.codehilite .s1 {
    color: hsl(86, 57%, 40%);
} /* Literal.String.Single */
.codehilite .ss {
    color: hsl(241, 68%, 28%);
} /* Literal.String.Symbol */
.codehilite .bp {
    color: hsl(120, 100%, 25%);
} /* Name.Builtin.Pseudo */
.codehilite .vc {
    color: hsl(241, 68%, 28%);
} /* Name.Variable.Class */
.codehilite .vg {
    color: hsl(241, 68%, 28%);
} /* Name.Variable.Global */
.codehilite .vi {
    color: hsl(241, 68%, 28%);
} /* Name.Variable.Instance */
.codehilite .il {
    color: hsl(0, 0%, 40%);
} /* Literal.Number.Integer.Long */


body.night-mode .codehilite code,
body.night-mode .codehilite pre {
    color: hsl(212, 100%, 82%);
    background-color: hsl(212, 25%, 15%);
}

body.night-mode .codehilite .hll {
    background-color: hsl(0, 0%, 13%);
}

body.night-mode .codehilite .err {
    color: hsl(1, 67%, 66%);
    background-color: hsl(0, 7%, 22%);
}

body.night-mode .codehilite .k {
    color: hsl(31, 85%, 59%);
}

body.night-mode .codehilite .p {
    color: hsl(179, 27%, 35%);
}

body.night-mode .codehilite .cs {
    color: hsl(0, 100%, 40%);
    font-weight: 700;
}

body.night-mode .codehilite .gd {
    color: hsl(0, 100%, 40%);
}

body.night-mode .codehilite .ge {
    color: hsl(0, 0%, 80%);
    font-style: italic;
}

body.night-mode .codehilite .gr {
    color: hsl(0, 100%, 50%);
}

body.night-mode .codehilite .go {
    color: hsl(0, 0%, 50%);
}

body.night-mode .codehilite .gs {
    color: hsl(0, 0%, 80%);
    font-weight: 700;
}

body.night-mode .codehilite .gu {
    color: hsl(300, 100%, 25%);
    font-weight: 700;
}

body.night-mode .codehilite .gt {
    color: hsl(222, 100%, 41%);
}

body.night-mode .codehilite .kc {
    color: hsl(0, 45%, 75%);
}

body.night-mode .codehilite .kd {
    color: hsl(60, 100%, 76%);
}

body.night-mode .codehilite .kn {
    color: hsl(24, 56%, 72%);
    font-weight: 700;
}

body.night-mode .codehilite .kp {
    color: hsl(62, 36%, 71%);
}

body.night-mode .codehilite .kr {
    color: hsl(359, 58%, 56%);
}

body.night-mode .codehilite .ni {
    color: hsl(359, 35%, 63%);
}

body.night-mode .codehilite .ne {
    color: hsl(53, 23%, 69%);
    font-weight: 700;
}

body.night-mode .codehilite .nn {
    color: hsl(204, 54%, 72%);
}

body.night-mode .codehilite .vi {
    color: hsl(60, 100%, 89%);
}

body.night-mode .codehilite .c,
body.night-mode .codehilite .g,
body.night-mode .codehilite .cm,
body.night-mode .codehilite .cp,
body.night-mode .codehilite .c1 {
    color: hsl(209, 15%, 55%);
}

body.night-mode .codehilite .l,
body.night-mode .codehilite .x,
body.night-mode .codehilite .no,
body.night-mode .codehilite .nd,
body.night-mode .codehilite .nl,
body.night-mode .codehilite .nx,
body.night-mode .codehilite .py,
body.night-mode .codehilite .w {
    color: hsl(0, 0%, 80%);
}

body.night-mode .codehilite .n,
body.night-mode .codehilite .nv,
body.night-mode .codehilite .vg {
    color: hsl(60, 19%, 83%);
}

body.night-mode .codehilite .o,
body.night-mode .codehilite .ow {
    color: hsl(58, 52%, 88%);
}

body.night-mode .codehilite .gh,
body.night-mode .codehilite .gp {
    color: hsl(60, 19%, 83%);
    font-weight: 700;
}

body.night-mode .codehilite .gi,
body.night-mode .codehilite .kt {
    color: hsl(120, 100%, 40%);
}

body.night-mode .codehilite .ld,
body.night-mode .codehilite .s,
body.night-mode .codehilite .sb,
body.night-mode .codehilite .sc,
body.night-mode .codehilite .sd,
body.night-mode .codehilite .s2,
body.night-mode .codehilite .se,
body.night-mode .codehilite .sh,
body.night-mode .codehilite .si,
body.night-mode .codehilite .sx,
body.night-mode .codehilite .sr,
body.night-mode .codehilite .s1,
body.night-mode .codehilite .ss {
    color: hsl(0, 36%, 69%);
}

body.night-mode .codehilite .m,
body.night-mode .codehilite .mf,
body.night-mode .codehilite .mh,
body.night-mode .codehilite .mi,
body.night-mode .codehilite .mo,
body.night-mode .codehilite .il {
    color: hsl(183, 45%, 69%);
}

body.night-mode .codehilite .na,
body.night-mode .codehilite .nt {
    color: hsl(127, 25%, 68%);
}

body.night-mode .codehilite .nb,
body.night-mode .codehilite .nc,
body.night-mode .codehilite .nf,
body.night-mode .codehilite .bp,
body.night-mode .codehilite .vc {
    color: hsl(60, 75%, 75%);
}
